import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import "./index.scss";

interface BlogPost {
  id: number;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
  author: number;
  date: string;
  featured_media: number;
}

interface Author {
  id: number;
  name: string;
}

const truncateText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substr(0, maxLength) + "...";
};

const News: React.FC = () => {
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
  const [authors, setAuthors] = useState<Record<number, Author>>({});
  const [featuredImages, setFeaturedImages] = useState<Record<number, string>>(
    {}
  );

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await fetch(
          "https://rainscountyevents.com/content/wp-json/wp/v2/posts"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch blog posts");
        }
        const data = await response.json();
        setBlogPosts(data);
      } catch (error: any) {
        console.error("Error fetching blog posts:", error.message);
      }
    };

    const fetchAuthors = async () => {
      try {
        const response = await fetch(
          "https://rainscountyevents.com/content/wp-json/wp/v2/users"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch authors");
        }
        const data: Author[] = await response.json();
        const authorMap: Record<number, Author> = {};
        data.forEach((author) => {
          authorMap[author.id] = author;
        });
        setAuthors(authorMap);
      } catch (error: any) {
        console.error("Error fetching authors:", error.message);
      }
    };

    const fetchFeaturedImages = async () => {
      try {
        const response = await fetch(
          "https://rainscountyevents.com/content/wp-json/wp/v2/media?per_page=100"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch featured images");
        }
        const data: { id: number; source_url: string }[] =
          await response.json();
        const featuredImageMap: Record<number, string> = {};
        data.forEach((image) => {
          featuredImageMap[image.id] = image.source_url;
        });
        setFeaturedImages(featuredImageMap);
      } catch (error: any) {
        console.error("Error fetching featured images:", error.message);
      }
    };

    fetchBlogPosts();
    fetchAuthors();
    fetchFeaturedImages();
  }, []);

  return (
    <div className="news-page">
      <Header />
      <div className="container">
        <h1>News</h1>
        <ul className="news-grid">
          {blogPosts.map((post) => (
            <li className="post" key={post.id}>
              {post.featured_media !== undefined &&
                post.featured_media !== 0 && (
                  <img
                    src={featuredImages[post.featured_media]}
                    alt="Featured"
                  />
                )}
              <span className="author">{authors[post.author]?.name}</span>
              <Link className="title-link" to={`/post/${post.id}`}>
                <h3>{post.title.rendered}</h3>
              </Link>
              <span className="date">
                {new Date(post.date).toLocaleDateString()}
              </span>
              <div
                className="content-preview"
                dangerouslySetInnerHTML={{
                  __html: truncateText(post.content.rendered, 300),
                }} // Change 200 to the desired character limit
              />
              <Link to={`/post/${post.id}`}>Read More</Link>
              <hr />
            </li>
          ))}
        </ul>
      </div>
      <Footer />
    </div>
  );
};

export default News;

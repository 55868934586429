import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./index.scss";

const headerLinks = [
  { path: "/", name: "Home" },
  { path: "/news", name: "News" },
  { path: "/solareclipse", name: "Solar Eclipse" },
  { path: "/countyfair", name: "County Fair" },
  { path: "/christmasnewyear", name: "Christmas & New Year" },
];

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header>
      <div className="header-content">
        <a href="/" className="logo">
          Rains County Events
        </a>
        {isMobile && (
          <button
            className={`menu-button ${isMenuOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <div></div>
            <div></div>
            <div></div>
          </button>
        )}
      </div>
      {isMobile && (
        <nav className={isMenuOpen ? "open" : ""}>
          <ul>
            {headerLinks.map((link, index) => (
              <li key={index}>
                <Link to={link.path} onClick={toggleMenu}>
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      )}
      {!isMobile && (
        <nav className="desktop">
          <ul>
            {headerLinks.map((link, index) => (
              <li key={index}>
                <Link to={link.path}>{link.name}</Link>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;

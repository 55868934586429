import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import HomepageHero from "../../components/homepage-hero";
import LiveUpdates from "../../components/live-updates";
import News from "../../components/news";
import heroBackground from "./images/rains-county-courthouse.jpg";
import iconEclipse from "./images/icon-eclipse.svg";
import Button from "../../components/button";
import "./index.scss";
import Contact from "../../components/contact";

const Home: React.FC = () => {
  return (
    <div>
      <Header />
      <HomepageHero backgroundImage={heroBackground}>
        <div className="hero-splash">
          <div className="heading">
            <div>
              <img src={iconEclipse} alt="Rains County Eclipse 2024" />
              <div className="title-group">
                <h1>2024 Solar Eclipse</h1>
                <h2 className="light-weight">April 8, 2024</h2>
              </div>
            </div>
            <p className="large">
              As the solar eclipse of 2024 graces Rains County, our dedicated
              Emergency Management Team is your source of information and
              assurance. Join us in celebrating this wonder while staying
              well-informed and secure.
            </p>
            <Button href="/solareclipse">Learn More</Button>
          </div>
        </div>
      </HomepageHero>
      <div className="announcement-bar">
        <div>
          <h3>Rains County Texas</h3>
          <span>Office of Emergency Management</span>
        </div>
        <p>
          This website is offered as a public service, to act as a Public
          Information and Public Awareness notification platform dedicated to
          providing Accurate, Up-To-Date information that is of special interest
          to the citizens of Rains County, as well as visitors.
        </p>
      </div>
      <div className="container flex">
        <div className="left">
          <LiveUpdates />
        </div>
        <div className="right">
          <News />
          <h2>Contact</h2>
          <Contact dark />
        </div>
      </div>
      <div className="contribute">
        <div>
          <span className="title">Contribute to Rains County Events</span>
          <p>
            If you possess information that could benefit and inform the
            community, kindly <span className="strong">email</span> the details,
            along with any necessary photos, to us. We will carefully evaluate
            the content for potential uploading to the website.
          </p>
        </div>
        <Button
          href="mailto:crystal.mayer@co.rains.tx.us"
          className="custom-button button light"
        >
          Send us an Email
        </Button>
      </div>
      <Footer />
    </div>
  );
};

export default Home;

// Hero.js
import React from "react";
import "./index.scss";

interface HeroProps {
  children?: React.ReactNode;
  subtitle: string;
  title: React.ReactNode; // Change the type to React.ReactNode
  copy: string;
  img: string;
}

const Hero: React.FC<HeroProps> = ({
  children,
  subtitle,
  title,
  copy,
  img,
}) => {
  return (
    <div className="page-hero container">
      <div>
        <span className="sub-title">{subtitle}</span>
        <h1 className="page-title">{title}</h1>
        <p className="copy">{copy}</p>
      </div>
      <div>
        <img src={img} alt="" />
      </div>
      <div className="content">{children}</div>
    </div>
  );
};

export default Hero;

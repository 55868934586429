import React from "react";
import "./index.scss";
import mapDark from "./images/map-dark.svg";
import emailDark from "./images/email-dark.svg";
import phoneDark from "./images/phone-dark.svg";
import map from "./images/map.svg";
import email from "./images/email.svg";
import phone from "./images/phone.svg";

interface ContactProps {
  dark?: boolean; // Optional prop indicating dark mode
}

const Contact: React.FC<ContactProps> = ({ dark }) => {
  // Determine which set of images to use based on the dark prop
  const mapImage = dark ? mapDark : map;
  const emailImage = dark ? emailDark : email;
  const phoneImage = dark ? phoneDark : phone;

  return (
    <div className={`contact-wrap ${dark ? "dark" : ""}`}>
      <div className="map">
        <img src={mapImage} alt="Rains County Emergency Services Address" />
        <div>
          189 E. North Street <br /> Emory, TX 75440
        </div>
      </div>
      <div className="phone">
        <ul>
          <li>
            <span>Call, Text and email are acceptable. </span>
          </li>
          <li>
            <span>Joe Parker</span>
            <span>
              Emergency Management Coordinator / Environmental Enforcement
            </span>
            <span>
              Office: <a href="tel:903-473-5025">903-473-5025</a>
            </span>
            <span>
              Cell: <a href="tel:903-473-5025">903-394-9775</a>
            </span>
            <a href="mailto:Joe.Parker@co.rains.tx.us" className="email">
              Joe.Parker@co.rains.tx.us
            </a>
          </li>
          <hr />
          <li>
            <span>Crystal Mayer</span>
            <span>Tier II & LEPC Coordinator / Environmental Enforcement.</span>
            <span>
              Office: <a href="tel:903-473-5083">903-473-5083 </a>
            </span>
            <span>
              Cell: <a href="903-394-9745">903-394-9745</a>
            </span>
            <a href="mailto:crystal.mayer@co.rains.tx.us" className="email">
              crystal.mayer@co.rains.tx.us
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Contact;

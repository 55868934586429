import React, { useEffect, useState } from "react";

interface LiveUpdatePost {
  id: number;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
}

const LiveUpdates: React.FC = () => {
  const [liveUpdates, setLiveUpdates] = useState<LiveUpdatePost[]>([]);

  useEffect(() => {
    const fetchLiveUpdates = async () => {
      try {
        const response = await fetch(
          "https://rainscountyevents.com/content/wp-json/wp/v2/live_updates"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch blog posts");
        }

        const data = await response.json();
        setLiveUpdates(data);
      } catch (error: any) {
        console.error("Error fetching blog posts:", error.message);
      }
    };

    fetchLiveUpdates();
  }, []);

  return (
    <section className="live-updates">
      <h2>Live Updates</h2>
      <ul className="live-updates-list">
        {liveUpdates.map((update) => (
          <li className="post" key={update.id}>
            <h3>{update.title.rendered}</h3>
            <div
              dangerouslySetInnerHTML={{ __html: update.content.rendered }}
            />
            <hr />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default LiveUpdates;

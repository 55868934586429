// Hero.js
import React from "react";
import "./index.scss";

interface HeroProps {
  backgroundImage: string;
  children?: React.ReactNode;
}

const HomepageHero: React.FC<HeroProps> = ({ backgroundImage, children }) => {
  const backgroundStyle: React.CSSProperties = {
    backgroundImage: `url(${backgroundImage})`,
  };

  return (
    <div className="hero" style={backgroundStyle}>
      <div className="overlay"></div>
      <div className="content">{children}</div>
    </div>
  );
};

export default HomepageHero;

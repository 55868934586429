import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Link } from "react-router-dom";
import "./index.scss";
import BackArrow from "./images/back-arrow.svg";

interface BlogPost {
  id: number;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
  author: number;
  date: string;
  featured_media: number;
}

interface Author {
  id: number;
  name: string;
}

const PostDetail: React.FC = () => {
  const { postId } = useParams<{ postId: string }>();
  const [post, setPost] = useState<BlogPost | null>(null);
  const [author, setAuthor] = useState<Author | null>(null);
  const [featuredImageUrl, setFeaturedImageUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        // Fetch post details
        const postResponse = await fetch(
          `https://rainscountyevents.com/content/wp-json/wp/v2/posts/${postId}`
        );
        if (!postResponse.ok) {
          throw new Error("Failed to fetch post");
        }
        const postData: BlogPost = await postResponse.json();
        setPost(postData);

        // Fetch author details
        const authorResponse = await fetch(
          `https://rainscountyevents.com/content/wp-json/wp/v2/users/${postData.author}`
        );
        if (!authorResponse.ok) {
          throw new Error("Failed to fetch author");
        }
        const authorData: Author = await authorResponse.json();
        setAuthor(authorData);

        // Fetch featured image URL
        if (postData.featured_media) {
          const imageUrlResponse = await fetch(
            `https://rainscountyevents.com/content/wp-json/wp/v2/media/${postData.featured_media}`
          );
          if (!imageUrlResponse.ok) {
            throw new Error("Failed to fetch featured image URL");
          }
          const imageUrlData: { source_url: string } =
            await imageUrlResponse.json();
          setFeaturedImageUrl(imageUrlData.source_url);
        }
      } catch (error: any) {
        console.error("Error fetching post details:", error.message);
      }
    };

    fetchPost();
  }, [postId]);

  if (!post || !author) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header />
      <div className="post-detail container">
        <Link to={"/news"} className="all-article-link">
          <img src={BackArrow} alt="View all articles" />
          View all articles
        </Link>
        <h2>{post.title.rendered}</h2>
        <div className="post-details">
          <span>{author.name}</span>
          <span>{new Date(post.date).toLocaleDateString()}</span>
        </div>
        {featuredImageUrl && (
          <div className="featured-image">
            <img src={featuredImageUrl} alt="Featured" />{" "}
          </div>
        )}
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: post.content.rendered }}
        />
      </div>
      <Footer />
    </>
  );
};

export default PostDetail;

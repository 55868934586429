import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import FAQ from "../../components/faq";
import Hero from "../../components/hero";
import solarHero from "./images/solar-eclipse.jpg";
import solarBreak from "./images/solar-eclipse-page-break.svg";

import "./index.scss";

const SolarEclipse: React.FC = () => {
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        const response = await fetch(
          "https://rainscountyevents.com/content/wp-json/wp/v2/pages/17"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch page content");
        }
        const data = await response.json();
        setContent(data.content.rendered);
      } catch (error) {
        console.error("Error fetching page content:", error);
      }
    };

    fetchPageContent();
  }, []);
  const heroTitle = (
    <div>
      <div className="solar-title">Solar Eclipse</div>
      <div className="solar-date">April 8 2024</div>
    </div>
  );

  return (
    <div className="solar-eclipse-page">
      <Header />
      <Hero
        subtitle="Rains County"
        title={heroTitle}
        copy="As the solar eclipse of 2024 graces Rains County, our dedicated Emergency Management Team is your source of information and assurance. Join us in celebrating this wonder while staying well-informed and secure."
        img={solarHero}
      />
      <img src={solarBreak} className="page-break" alt="" />
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <FAQ />
      </div>
      <Footer />
    </div>
  );
};

export default SolarEclipse;

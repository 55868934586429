// Button.tsx

import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from "react";
import "./index.scss"; // Import component styles

// Define props for both anchor and button elements
type ButtonProps = {
  href?: string;
  target?: string;
  children: React.ReactNode;
} & AnchorHTMLAttributes<HTMLAnchorElement> &
  ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ButtonProps> = ({ href, target, children, ...rest }) => {
  // Determine which element to render based on the presence of href
  if (href) {
    return (
      <a href={href} target={target} className="custom-button" {...rest}>
        {children}
      </a>
    );
  } else {
    return (
      <button type="button" className="custom-button" {...rest}>
        {children}
      </button>
    );
  }
};

export default Button;

import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";

const ChristmasNewYear: React.FC = () => {
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        const response = await fetch(
          "https://rainscountyevents.com/content/wp-json/wp/v2/pages/19"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch page content");
        }
        const data = await response.json();
        setContent(data.content.rendered);
      } catch (error) {
        console.error("Error fetching page content:", error);
      }
    };

    fetchPageContent();
  }, []);
  return (
    <div>
      <Header />
      <div className="container">
        <h1>Christmas & New Year</h1>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      <Footer />
    </div>
  );
};

export default ChristmasNewYear;

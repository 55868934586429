import React from "react";
import { Link } from "react-router-dom";
import Contact from "../contact/index";
import "./index.scss";

const headerLinks = [
  { path: "/", name: "Home" },
  { path: "/news", name: "News" },
  { path: "/solareclipse", name: "Solar Eclipse" },
  { path: "/countyfair", name: "County Fair" },
  { path: "/christmasnewyear", name: "Christmas & New Year" },
];

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="footer-wrap">
        <div>
          <h3>Rains County Texas</h3>
          <span>Office of Emergency Management</span>
        </div>
        <ul className="navigation">
          {headerLinks.map((link, index) => (
            <li key={index}>
              <Link to={link.path}>{link.name}</Link>
            </li>
          ))}
        </ul>
        <Contact />
      </div>
      <p className="copyright">&copy; 2024 Rains County Events</p>
    </footer>
  );
};

export default Footer;

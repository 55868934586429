import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import News from "./pages/news";
import SolarEclipse from "./pages/solar-eclipse";
import CountyFair from "./pages/county-fair";
import ChristmasNewYear from "./pages/christmas-newyear";
import PostDetail from "./pages/post-detail";
import "./styles/globalStyles.scss";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/news" Component={News} />
        <Route path="/solareclipse" Component={SolarEclipse} />
        <Route path="/countyfair" Component={CountyFair} />
        <Route path="/christmasnewyear" Component={ChristmasNewYear} />
        <Route path="/post/:postId" Component={PostDetail} />
      </Routes>
    </Router>
  );
};

export default App;

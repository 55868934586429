import React, { useState } from "react";
import "./index.scss";

interface Faq {
  question: string;
  answer: string;
}

interface FaqAccordionProps {
  faqs: Faq[];
}

const FaqAccordion: React.FC<FaqAccordionProps> = ({ faqs }) => {
  const [activeIndexes, setActiveIndexes] = useState<number[]>([]);

  const toggleAccordion = (index: number) => {
    setActiveIndexes((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        // If the index is already in the array, remove it
        return prevIndexes.filter((i) => i !== index);
      } else {
        // If the index is not in the array, add it
        return [...prevIndexes, index];
      }
    });
  };

  return (
    <div className="faq-accordion">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div
            className={`faq-title ${
              activeIndexes.includes(index) ? "active" : ""
            }`}
            onClick={() => toggleAccordion(index)}
          >
            {faq.question}{" "}
            <span>{activeIndexes.includes(index) ? "-" : "+"}</span>
          </div>
          {activeIndexes.includes(index) && (
            <div className="faq-answer">{faq.answer}</div>
          )}
        </div>
      ))}
    </div>
  );
};

const FAQData: Faq[] = [
  {
    question:
      "What measures are in place to handle increased tourism or visitors during the solar eclipse?",
    answer:
      "Our Emergency Management Team is coordinating with local authorities to ensure adequate infrastructure and support services are available to manage increased tourism. This includes additional law enforcement, traffic management plans, and enhanced public facilities.",
  },
  {
    question:
      "What safety precautions should residents take during the solar eclipse?",
    answer:
      "Residents should use certified eclipse glasses to protect their eyes when viewing the solar eclipse. Additionally, avoid looking directly at the sun without proper eye protection, and do not use homemade filters or ordinary sunglasses. It's also crucial to stay hydrated, especially if spending extended periods outdoors during the event.",
  },
  {
    question:
      "Will there be any impact on public services during the solar eclipse?",
    answer:
      "While there may be temporary adjustments to public services such as transportation schedules or road closures, our aim is to minimize disruptions. Emergency services will remain fully operational to ensure the safety and well-being of residents and visitors throughout the solar eclipse.",
  },
  {
    question: "What is the duration of the solar eclipse in our area?",
    answer:
      "The duration of the solar eclipse in our area is approximately [insert duration here]. This encompasses the period of partial eclipse leading up to totality and the subsequent partial eclipse as the moon moves away from its alignment with the sun.",
  },
  {
    question:
      "Where are the best locations within the county to view the solar eclipse?",
    answer:
      "Prime locations within Rains County for viewing the solar eclipse include [insert locations here]. These spots offer unobstructed views of the sky and ample space for observers to gather and enjoy the celestial event.",
  },
  {
    question:
      "Are there any organized events or activities planned for the solar eclipse?",
    answer:
      "Yes, there are several organized events and activities planned to celebrate the solar eclipse in Rains County. These include community gatherings, educational workshops, and special viewing parties. Stay tuned for updates on specific events and how to participate!",
  },
];

const App: React.FC = () => {
  return (
    <div className="faq-wrapper">
      <h2>Frequently Asked Questions</h2>
      <FaqAccordion faqs={FAQData} />
    </div>
  );
};

export default App;
